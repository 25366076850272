.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #b4b4b4;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #a0a0a0;
}
.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}
.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}
.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #b4b4b4;
  border-radius: 50%;
  background-color: #FAFAFA;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}
.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}
.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}
.input-group {
  width: 100%;
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  display: flex;
  align-items: center;
}
.input-grouplabel,
.input-group .label-text {
  vertical-align: middle;
  font-weight: normal;
  margin-bottom: 0;
}
.input-group .label-text {
  margin-left: 1em;
  font-size: 16px;
}
.input-group:not(:last-child) {
  margin-bottom: 4px;
}
.input-group input,
.input-group textarea {
  font-weight: 300;
  padding: 5px;
  margin-right: 4px;
  width: 100%;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
}
.input-group textarea {
  height: 90px;
  outline: 0;
  overflow: auto;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.form {
  display: flex;
  flex-direction: column;
}
.form > *:not(:last-child) {
  margin-bottom: 0.75rem;
}
.form .form-header {
  margin-bottom: 1rem;
  font-size: 20px;
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  font-weight: bold;
}
.parallel-forms {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.parallel-forms > *:not(:last-child) {
  padding-right: 0.75rem;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}
.parallel-forms > * {
  width: 100%;
}
.parallel-forms > *:not(:first-child) {
  margin-left: 0.75rem;
}
.bibliography {
  display: flex;
  flex-direction: column;
}
.talks-container .talks-wrapper:not(.condensed) > *:not(:last-child) {
  margin-bottom: 1rem;
}
#go-game.go-game.go-game-small .board {
  width: 300px;
  max-height: 300px;
}
#go-game.go-game.go-game-small .board svg {
  width: 300px;
  height: 300px;
}
#go-game .board,
.go-game .board {
  margin: 45px 45px 30px 30px;
  background-color: #ddb454;
  width: 400px;
  max-height: 400px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}
#go-game .board svg,
.go-game .board svg {
  overflow-x: visible;
  overflow-y: visible;
  width: 400px;
  height: 400px;
  margin-bottom: -3px;
}
#go-game .board svg rect,
.go-game .board svg rect {
  fill: rgba(255, 255, 255, 0.08);
}
#go-game .board svg line,
.go-game .board svg line {
  stroke: rgba(0, 0, 0, 0.8);
  stroke-width: 0.05;
  stroke-linecap: square;
}
#go-game .table,
.go-game .table {
  margin-top: 4rem;
  display: inline-block;
}
#go-game .label,
.go-game .label {
  text-transform: uppercase;
  font-size: 0.8px;
  font-family: 'Roboto', sans-serif;
}
#go-game .label.y-label,
.go-game .label.y-label {
  alignment-baseline: middle;
}
#go-game .piece,
.go-game .piece {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
}
#go-game .piece:hover,
.go-game .piece:hover {
  stroke: green;
  stroke-width: 0.1;
}
#go-game .piece.black,
.go-game .piece.black {
  fill: black;
}
#go-game .piece.white,
.go-game .piece.white {
  fill: white;
}
#go-game .piece-target,
.go-game .piece-target {
  opacity: 0;
}
.portal-wrapper #go-game .table {
  margin-top: 2rem;
}
.markdown p,
.markdown li,
.markdown span,
.markdown a {
  font-size: 16px;
  font-weight: 400;
}
.markdown pre code span,
.markdown pre code {
  font-size: 14px;
}
.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  line-height: 1.3em;
  font-weight: 600;
}
.markdown ol,
.markdown ul {
  margin-top: 1em;
  margin-bottom: 1em;
}
.markdown li:before {
  font-size: 14px;
}
.no-indent p,
.no-indent .markdown p {
  text-indent: 0;
}
.inline-code-decoration {
  background-color: #fcebe5;
  display: inline-block;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 2px;
  border: 1px solid #f4b6a0;
  margin-right: 0.05em;
}
.markdown > *:not(pre) > code,
.markdown *:not(pre) *:not(pre) > code,
.markdown figcaption code {
  background-color: #fcebe5;
  display: inline-block;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 2px;
  border: 1px solid #f4b6a0;
  margin-right: 0.05em;
}
.markdown > h1 > code,
.markdown > h2 > code,
.markdown > h3 > code,
.markdown > h4 > code,
.markdown > h5 > code,
.markdown > h6 > code {
  line-height: 1.4em;
}
.markdown p a code {
  text-decoration: underline;
  color: #175885;
  border-color: #aad4f1;
  background-color: #ecf5fc;
}
.markdown p a code:hover {
  background-color: #d6eaf8;
}
.markdown-centered {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#dominion section,
.markdown section {
  width: 500px;
}
#dominion section p,
.markdown section p,
#dominion section p,
.markdown section p {
  margin-bottom: 10px;
  line-height: 1.3;
}
#dominion section h1,
.markdown section h1,
#dominion section h2,
.markdown section h2 {
  margin-bottom: 20px;
}
#dominion section h1,
.markdown section h1 {
  font-size: 24px;
}
#dominion section:not(:last-child),
.markdown section:not(:last-child) {
  margin-bottom: 30px;
}
#dominion #editor-container,
.markdown #editor-container {
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
#dominion #run-button:disabled,
.markdown #run-button:disabled,
#dominion #run-button:disabled:hover,
.markdown #run-button:disabled:hover {
  background-color: rgba(255, 235, 0, 0.4);
}
#dominion #run-button,
.markdown #run-button {
  height: 28px;
  width: 120px;
  border-width: 1px;
  border-color: #e8e8e8;
  font-size: 14px;
  color: #505050;
}
#dominion #run-button:hover,
.markdown #run-button:hover {
  background-color: rgba(109, 189, 35, 0.2);
}
.wide .markdown {
  width: 700px;
}
.markdown {
  width: 550px;
}
.markdown h1 {
  font-size: 24px;
}
.markdown h2 {
  font-size: 20px;
}
.markdown h3 {
  font-size: 18px;
}
.markdown a {
  text-decoration: underline;
}
.markdown p {
  line-height: 1.6;
  margin-bottom: 12px;
}
.markdown p * {
  text-indent: initial;
}
.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  margin-bottom: 12px;
  margin-top: 20px;
  color: #000;
}
.markdown > div > *:first-child {
  margin-top: 0;
}
.markdown h1 {
  margin-top: 45px;
}
.markdown h2 {
  color: #444444;
  margin-top: 35px;
}
.inline-markdown .markdown {
  display: inline;
}
.inline-markdown .markdown p {
  display: inline;
}
.katex {
  font: normal 1.1em KaTeX_SansSerif, sans-serif !important;
}
.inline-markdown .katex {
  font: normal 1em KaTeX_SansSerif, sans-serif !important;
}
.markdown figcaption {
  font-size: 14px;
  font-weight: 300;
}
.head-note {
  padding: 1em;
  background-color: #f8f8f8;
  border-radius: 5px;
  margin: 1em 1em;
}
.head-note :last-child {
  margin-bottom: 0;
}
ol.bibliography,
ol {
  padding-left: 2em;
  list-style: none outside;
  counter-reset: item;
}
ol.bibliography li,
ol li {
  display: block;
  counter-increment: item;
  position: relative;
}
ol.bibliography li:before,
ol li:before {
  position: absolute;
  left: -1.1em;
  content: counter(item);
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  font-weight: 800;
  font-size: 12px;
  padding-top: 1px;
  margin-right: 1em;
  vertical-align: middle;
  display: inline-block;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loose > * {
  margin: 1em;
}
.welcome-portal-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcome-portal-item header {
  margin-bottom: 1rem;
}
.callout {
  margin-left: 0.75rem;
  padding-left: 0.75rem;
  border-left: 2px solid #b4b4b4;
}
#app,
a {
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
}
* {
  box-sizing: border-box;
}
#project-description-list {
  width: 660px;
}
#project-description-list > div:not(:last-child) {
  margin-bottom: 60px;
}
#project-description-list a,
#project-description-list h1 {
  font-size: 21px;
  color: #444;
}
#project-description-list a {
  text-decoration: underline;
}
#project-description-list p {
  margin-top: 5px;
  line-height: 1.3;
}
#project-description-list .project-content {
  vertical-align: top;
  width: 500px;
}
#project-description-list .project-image {
  width: 140px;
  height: 140px;
  border-radius: 70px;
  -webkit-border-radius: 70px;
  -moz-border-radius: 70px;
  margin-right: 20px;
  margin-top: 12px;
}
#resume {
  padding-top: 70px;
}
#resume .experience-sections > div:not(:last-child) {
  margin-bottom: 20px;
}
#resume h1 {
  font-size: 18px;
}
#resume h2 {
  font-size: 16px;
}
#resume .resume-row:not(:last-child) {
  margin-bottom: 20px;
}
#resume .experience-header {
  padding-bottom: 6px;
}
#resume i {
  font-size: 14px;
  font-weight: 300;
}
.table-div {
  display: table;
  table-layout: fixed;
}
.table-div > * {
  display: table-cell;
}
.resume-row > *:first-child {
  width: 140px;
}
.formula {
  margin-right: 1px !important;
  margin-left: 1px !important;
}
a,
p,
span {
  font-size: 14px;
}
h2 {
  color: #444;
  font-size: 21px;
}
a {
  text-decoration: underline;
  font-weight: 400;
  color: #000;
}
nav a,
nav button {
  text-decoration: none;
  color: #444;
}
nav ul {
  float: left;
}
.welcome-callout {
  list-style: none outside;
  margin: 0 0 20px 0;
  padding: 0;
}
.talks-container ul,
.nav-container ul {
  list-style: none outside;
  margin: 0 0 20px 0;
  padding: 0;
}
.nav-container {
  float: left;
  display: inline;
  margin-left: 1rem;
  margin-right: 10px;
  width: 120px;
  line-height: 1rem;
}
.nav-container #name-header {
  font-family: 'Roboto Slab', Georgia, serif;
  font-weight: 400;
  margin-bottom: 70px;
  text-transform: lowercase;
  line-height: 1.2rem;
}
.container {
  position: relative;
  padding-top: 60px;
  width: 960px;
  margin: 0 auto;
}
body,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.talks-section-header {
  margin-bottom: 20px;
}
.talks-header {
  height: 80px;
}
.projects-content {
  margin-top: 70px;
}
.project-container:not(:last-child),
.blog:not(:last-child) {
  margin-bottom: 30px;
}
.project-container h2,
.blog h2 {
  font-size: 20px;
}
.project-container .project-description,
.blog .project-description,
.project-container .blog-short,
.blog .blog-short {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 300;
}
.welcome-callout {
  margin-top: 1rem;
  margin-left: 0.75rem;
  padding-left: 0.75rem;
  border-left: 2px solid #b4b4b4;
}
.welcome-callout header strong {
  color: black;
}
.nav-section button {
  font-size: smaller;
  text-transform: lowercase;
  border: none;
  padding: 0;
  background-color: transparent;
  font-weight: 500;
  text-align: left;
  display: inline;
}
.nav-section .contents {
  margin-left: 1rem;
  margin-top: 1rem;
}
.nav-separator {
  padding: 7px;
  margin: 0;
}
.simple-button {
  margin: 0;
  height: 40px;
  border: solid 2px #404040;
  border-top: 0;
  background-color: white;
  font-size: 18px;
  color: #505050;
}
.simple-button:hover {
  background-color: rgba(109, 189, 35, 0.2);
}
.talks-wrapper * {
  font-size: 14px !important;
}
.talks-wrapper p {
  line-height: 1.6;
}
.talks-wrapper a {
  text-decoration: none;
  color: #DD4814;
  font-weight: 300;
}
.talks-container {
  margin-bottom: 60px;
}
.talks-container li {
  margin-bottom: 0.5rem;
}
.books-header {
  height: 80px;
}
.book {
  font-size: 14px;
}
.book cite {
  font-weight: 400;
}
.book:not(:last-child) {
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #eee;
}
.contact-container {
  width: 350px;
}
.content-container {
  width: 500px;
  position: absolute;
  left: 160px;
  margin: 0 0 0 0;
  padding-left: 60px;
  padding-bottom: 150px;
  padding-top: 70px;
  line-height: 1.2rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.low-aligned {
  padding-top: 70px;
}
.contact-table {
  width: 500px;
}
.table {
  width: 100%;
  display: table;
}
.table > * {
  display: table-cell;
}
.table .heading-left {
  width: 100px;
}
.table .right {
  text-align: right;
}
.statement p {
  margin-bottom: 10px;
}
#resume-link {
  padding-top: 70px;
}
#opponent-div {
  display: none;
  margin-top: 20px;
}
#opponent-div img {
  margin: auto;
}
#content-header {
  height: 150px;
  position: relative;
}
#resume {
  width: 720px;
}
.just-left-container {
  float: left;
}
.just-left-container p {
  text-align: left;
}
.just-right-container {
  float: right;
}
.just-right-container p {
  text-align: right;
}
.just-left,
.just-right {
  vertical-align: top;
  display: inline;
}
.just-container {
  overflow: hidden;
  width: 100%;
}
.just-left {
  text-align: left;
}
.just-right {
  text-align: right;
}
.resume-section {
  overflow: hidden;
  margin-bottom: 18px;
}
.content-leader {
  margin-bottom: 5px;
}
.resume-paragraphs {
  line-height: 16px;
}
.resume-paragraphs p {
  text-align: justify;
}
.resume-paragraphs p:not(:last-child) {
  margin-bottom: 5px;
}
.resume-section-content {
  width: 580px;
}
.resume-section-content > :last-child {
  margin-bottom: 0px;
}
.resume-section-title {
  width: 140px;
}
.resume-section-content,
.resume-section-title {
  float: left;
}
#resume-header {
  margin-bottom: 20px;
}
.hruled {
  padding-bottom: 10px;
  border-bottom: 1px solid #888;
  margin-bottom: 5px;
}
li {
  padding: 0 0 8px 0;
}
p,
span {
  font-weight: 300;
}
.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}
.x.axis path {
  display: none;
}
.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 1.5px;
}
.phone-number {
  word-spacing: 5px;
}
.hljs {
  border-left: 1px;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-style: solid;
  border-color: #e8e8e8;
}
a.external {
  display: inline-block;
}
a.external strong {
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  font-weight: 700;
}
a.external .link-decoration {
  color: green;
}
.breadcrumb a {
  font-weight: 600;
  text-decoration: underline;
  text-transform: lowercase;
}
#tax-explorer svg rect {
  border-radius: 0;
}
.legend {
  float: right;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-size: 12px;
  line-height: 0.9em;
  color: #1e1e1e;
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  margin: 5px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.02);
}
.legend .title {
  margin-bottom: 0.75em;
  font-weight: normal;
  font-size: 16px;
}
.info-block {
  margin-top: 1.5rem;
}
.info-block header {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.info-block .content {
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  border: 1px solid #cccccc;
  padding: 0.5rem;
}
#welcome-portal {
  margin-top: 3.5em;
}
#welcome-portal header h1,
#welcome-portal header h1 a {
  font-size: 20px;
}
#welcome-portal .example-marriage-diagram .lede {
  margin: 0.5em 1em 2em 1em;
  padding-bottom: 1em;
  font-weight: bold;
  font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
#welcome-portal .example-marriage-diagram div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
